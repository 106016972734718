import App from './container/App';
import Forbidden403 from './component/Forbidden/403';
import Sign from './component/Sign/Index';
import HotMap from './component/Statistics/HotMap';
import TimeLine from './component/Statistics/TimeLine';
import SelectRoot from './component/Org/Root';
import SelectDept from './component/Org/Dept';
import SelectAllSub from './component/Org/AllSub';

export default function routes(store) {
  return [
    {
      path: '/403',
      component: Forbidden403,
    },
    {
      component: App,
      routes:[
        {
          path: '/',
          exact: true,
          component: Sign,
        }, {
          path: '/hotmap',
          component: HotMap,
        }, {
          path: '/timeline',
          component: TimeLine,
        }, {
          path: '/select',
          exact: true,
          component: SelectRoot,
        }, {
          path: '/select/dept/:orgId',
          component: SelectDept,
        }, {
          path: '/select/allsub/:deptId',
          component: SelectAllSub,
        }
      ]
    }
  ];
}
