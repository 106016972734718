/*
 * @Author: guoguo 
 * @Date: 2018-10-18 16:48:56 
 * @Last Modified by: songyang
 * @Last Modified time: 2023-01-31 16:40:17
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import * as action from '../actions/Base';
import { bindActionCreators } from 'redux';
import { Toast } from 'antd-mobile';
import ForbiddenErr from '../component/Forbidden/Err';
import '../assets/css/base.less';
import * as localForage from 'localforage';

@connect(
  state => ({
  }),
  dispatch => ({
    actions: bindActionCreators(action, dispatch),
  })
)
class App extends Component {
  constructor(props) {
    super(props);
    this.clientId = '';
    this.state = {
      renderErrPage: false,
      renderFlag: false,
    };
  }
  
  componentDidMount() {
    window.onload = () => {
      // 注入bridge脚本
      const script = document.createElement("script");
      script.src = "/bridgeWeb.js";
      document.body.appendChild(script);
    }
    Toast.loading('loading..', 0);
    // 尝试获取用户信息
    this.props.actions.getUserInfo()
      .then((user) => {
        // 初始化localForage
        localForage.config({
          name: `${user.domain}`,
        });
        // 获取用户信息成功，隐藏loading，并开始渲染页面
        Toast.hide();
        this.setState({
          renderFlag: true,
        });
      })
      .catch((err) => {
        this.clientId = err.response && err.response.data && err.response.data.data;
        // 获取用户信息失败，判定用户没有登录，添加onBridgeReady监听，供bridge调用
        document.addEventListener('onBridgeReady', this.bridgeReady);
      });

    // 设置html的字体大小，为rem服务
    const html = document.documentElement;
    let hw = document.documentElement.clientWidth;
    if(hw > 500 ){
      hw = 500;
    }
    html.style.fontSize = hw/10 + 'px';
    window.onresize = () => {
      console.log('window resize, document clentH is:', document.documentElement.clientHeight);
      let hw = document.documentElement.clientWidth;
      if(hw > 500 ){
        hw = 500;
      }
      html.style.fontSize = hw/10 + 'px';
    };
  }
  /**
   * @description 原生端能力准备完毕之后会调用该方法
   */
  bridgeReady = () => {
    console.log('this.clientId is:', this.clientId);
    window.api.requestAuthCode({
      // h5AppId: '19ee296fcc564dcc99959477ccfb4c3c', // 生产环境appId
      // h5AppId: '244ee873d3e74eb0909f7e0b80192ad8', // 沙箱环境appId
      h5AppId: this.clientId,
    }, (ret) => {
      console.log('verCode TimeOut is:', Date.parse(new Date()));
      console.log('res:', ret);
      console.log('authCode is:', ret.authCode);
      const authCode = ret.authCode;
      this.props.actions.LoginForCode(authCode)
      .then((user) => {
        console.log('LoginForCode is:', Date.parse(new Date()));
        // 初始化localForage
        localForage.config({
          name: `${user.domain}`,
        });
        // 获取用户信息成功，隐藏loading，并开始渲染页面
        Toast.hide();
        this.setState({
          renderFlag: true,
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          Toast.fail('权限不足！', 2);
          // 2s后重定向到403页面
          setTimeout(() => {
            this.props.history.replace('/403');
          }, 2000);
        } else {
          Toast.fail('加载错误！', 2);
          // 渲染错误页面
          this.setState({
            renderFlag: true,
            renderErrPage: true,
          });
        }
      });
    });
  }
  
  render () {
    const { renderFlag, renderErrPage } = this.state;
    return renderFlag ? (
      renderErrPage ? (
        <ForbiddenErr />
      ) : (
        renderRoutes(this.props.route.routes)
      )
    ) : null;
  }
}

App.propTypes = {
  route: PropTypes.object,
  actions: PropTypes.object,
};

export default App;
