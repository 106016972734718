/* @desc 选择用户
 * @Author: guoguo;
 * @Date: 2020-04-07 10:15:22;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2020-04-07 10:15:22;
 */

import React, { Component } from 'react';
import { List, Toast } from 'antd-mobile';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as action from '../../actions/Org';
import { bindActionCreators } from 'redux';
import * as localForage from 'localforage';
require('./Org.less');

const ListItem = List.Item;

@connect(
  state => ({
    userInfo: state.Base.userInfo,
  }),
  dispatch => ({
    actions: bindActionCreators(action, dispatch),
  })
)
class Dept extends Component{
  constructor(props) {
    super(props);
    this.state = {
      deptList: [],
      noData: false, // 无数据
    };
  }
  componentWillMount() {
    const { userInfo } = this.props;
    const orgId = this.props.match.params.orgId;
    Toast.loading('loading..', 0);
    // 尝试从缓存中获取当前组织机构下的部门列表
    localForage.getItem(`dept_list_${orgId}`)
      .then(result => {
        if (result && result.length) {
          // 存在缓存数据
          Toast.hide();
          this.setState({
            deptList: result,
          });
        }
      });
    // 从远端获取部门列表
    this.props.actions.getDeptList(orgId, userInfo.domain)
      .then(res => {
        Toast.hide();
        this.setState({
          deptList: res.data || [],
          noData: !res.data || !res.data.length,
        });
      });
  }

  /**
   * @description 渲染部门列表
   */
  renderDeptList = () => {
    const { deptList } = this.state;
    return deptList.map(item => (
      <ListItem arrow="horizontal" multipleLine key={item.id} onClick={() => this.goToAllSub(item.id)}>
        {item.name}
      </ListItem>
    ));
  }
  /**
   * @description 跳转到部门下方子部门和成员页面
   */
  goToAllSub = (deptId) => {
    this.props.history.push(`/select/allsub/${deptId}`);
  }
  /**
   * @description 返回上一页
   */
  goBack = () => {
    window.history.back();
  }

  render() {
    const { noData } = this.state;
    return (
      <div className="dept-wrap">
        <List>
          <ListItem className="goback" onClick={this.goBack}>
            返回上页
          </ListItem>
          {
            this.renderDeptList()
          }
        </List>
        {
          noData ? (
            <div className="nodata-tips">暂无数据..</div>
          ) : null
        }
      </div>
    );
  }
}

Dept.propTypes = {
  actions: PropTypes.object,
};

export default Dept;
