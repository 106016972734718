/* @desc 选择用户
 * @Author: guoguo;
 * @Date: 2020-04-07 10:15:22;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2020-04-07 10:15:22;
 */

import React, { Component } from 'react';
import { List, Toast, Icon } from 'antd-mobile';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as action from '../../actions/Org';
import { bindActionCreators } from 'redux';
import * as localForage from 'localforage';
require('./Org.less');

const defaultAvatar = require('../../assets/img/default-avatar.png');

const ListItem = List.Item;

@connect(
  state => ({
    userInfo: state.Base.userInfo,
  }),
  dispatch => ({
    actions: bindActionCreators(action, dispatch),
  })
)
class AllSub extends Component{
  constructor(props) {
    super(props);
    this.state = {
      noData: false,
      allSubList: [],
    };
  }
  componentWillMount() {
    const deptId = this.props.match.params.deptId;
    this.getDatas(deptId);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.deptId !== nextProps.match.params.deptId) {
      this.getDatas(nextProps.match.params.deptId);
    }
  }
  /**
   * @description 获取成员数据
   */
  getDatas = (deptId) => {
    const { userInfo } = this.props;
    Toast.loading('loading..', 0);
    // 尝试从缓存中获取当前组织机构下的部门列表
    localForage.getItem(`sub_list_${deptId}`)
      .then(result => {
        if (result && result.length) {
          // 存在缓存数据
          Toast.hide();
          this.setState({
            allSubList: result,
          });
        }
      });
    // 从远端获取下级部门和成员列表
    this.props.actions.getSubAndPerson(deptId, userInfo.domain)
      .then(res => {
        Toast.hide();
        this.setState({
          allSubList: res.data || [],
          noData: !res.data || !res.data.length,
        });
      })
      .catch(err => {
        Toast.info('获取当前部门成员失败');
      });
  }

  /**
   * @description 渲染部门列表和成员列表
   */
  renderAllSubList = () => {
    const { allSubList } = this.state;
    return allSubList.map(item => (
      <ListItem
        arrow={item.type === 'user' ? null : 'horizontal'}
        extra={item.type === 'user' ? <Icon type="check-circle-o" /> : null}
        key={item.id}
        onClick={() => this.onSelect(item)}>
        {
          item.type === 'user' ? (
            <div className="user-wrap">
              <div className="avatar-box">
                {
                  item.headUrl ? (
                    <img src={item.headUrl} alt="" onError={e => e.target.src = defaultAvatar} />
                  ) : (
                    <img src={defaultAvatar} alt="" />
                  )
                }
              </div>
              <div className="name">{item.name}</div>
            </div>
          ) : (
            item.name
          )
        }
      </ListItem>
    ));
  }

  /**
   * @description 当前节点点击
   */
  onSelect = (item) => {
    console.log(item);
    if (item.type === 'user') {
      // 点击的是人员，保存到redux并关闭返回到打卡轨迹页
      console.log('onSelect item is :', item);
      this.props.actions.selectUser(item);
      this.props.history.replace('/timeline');
    } else {
      // 点击的是部门，查询下级部门机构
      this.props.history.push(`/select/allsub/${item.id}`);
    }
  }
  /**
   * @description 返回上一页
   */
  goBack = () => {
    window.history.back();
  }

  render() {
    const { noData } = this.state;
    return (
      <div className="allsub-wrap">
        <List>
          <ListItem className="goback" onClick={this.goBack}>
            返回上页
          </ListItem>
          {
            this.renderAllSubList()
          }
        </List>
        {
          noData ? (
            <div className="nodata-tips">暂无数据..</div>
          ) : null
        }
      </div>
    );
  }
}

AllSub.propTypes = {
  actions: PropTypes.object,
};

export default AllSub;
