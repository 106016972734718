/* @desc 打卡
 * @Author: guoguo;
 * @Date: 2020-03-31 14:14:14;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2020-03-31 14:14:14;
 */
import React, { Component } from 'react';
import { Toast } from 'antd-mobile';
import axios from 'axios';
import './Index.less';
import moment from 'moment';
import getRandom from '../../utils/getRandom';
import zx from '../../assets/js/zhixinHybrid.min.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
const jitang = require('./jitang.json');

@connect(
  state => ({
    userInfo: state.Base.userInfo,
  })
)
class Sign extends Component{
  constructor(props) {
    super(props);
    this.state = {
      hasMapInfo: false,
      lon: '', // 经度
      lat: '', // 纬度
      loading: true,
      btnActive: false,
      address: '', // 位置信息
      signing: false, // 正在打卡中
      success: false, // 打卡是否成功
      signSuccessTime: '', // 打卡成功时间
      successTips: false,
      successActive: false,
      signTime: moment().format('HH:mm'),
      result: {}, // 打卡奖励结果
      signTimerCount: 0,
      locateErrorMsg: '', // 定位失败message
    };
  }
  componentDidMount() {
    window.signThis = this;
    // 加载地图api
    window.AMap.plugin('AMap.Geolocation', function() {
      const geolocation = new window.AMap.Geolocation({
        enableHighAccuracy: true,// 是否使用高精度定位，默认:true
        timeout: 10000, // 超过5min后停止定位，默认：5s
        buttonPosition:'RB', // 定位按钮的停靠位置
        buttonOffset: new window.AMap.Pixel(10, 20),// 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        zoomToAccuracy: true, // 定位成功后是否自动调整地图视野到定位点
      });
      geolocation.getCurrentPosition();
      window.AMap.event.addListener(geolocation, 'complete', (result) => {
        if(result.type === 'complete'){
          // 定位成功
          console.log('定位成功', result);
          window.signThis.setState({
            loading: false,
            address: result.formattedAddress,
            hasMapInfo: true,
            lon: result.position.lng,
            lat: result.position.lat,
          });
        } else{
          console.log('获取位置信息失败，请刷新地图定位', result);
        }
      });
      window.AMap.event.addListener(geolocation, 'error', (error) => {
        console.log('获取定位信息错误', error);
        window.signThis.setState({
          locateErrorMsg: (error && error.message) || '',
        });
      });
      // 每秒钟重新获取一下定位
      setInterval(() => {
        window.signThis.setState({
          signTime: moment().format('HH:mm'),
        });
        console.log('do geolocation.getCurrentPosition');
        geolocation.getCurrentPosition();
      }, 1000);
    });
  }
  /**
   * @description 打卡
   */
  signStart = () => {
    const { loading, lon, lat, address, success } = this.state;
    if (success) {
      return;
    }
    this.setState({
      signing: true,
    });
    if (loading || !lon || !lat || !address) {
      // 地理位置还未获取到，轮询获取进度
      console.log('地理位置还未获取到，轮询获取进度');
      this.signTimer = setInterval(() => {
        if (this.state.signTimerCount > 10) {
          // 超过10s还未获取到用户信息，判定挖宝失败
          console.log('locateErrorMsg is: ', this.state.locateErrorMsg);
          if (this.state.locateErrorMsg.includes('permission denied')) {
            Toast.info(`刺客需要您的同意才能访问定位信息，如禁止将无法完成打卡，请在'设置'-'刺客'-'位置'中开启获取定位权限`);
          } else {
            Toast.info(`定位失败，无法完成打卡，请稍后再试`);
          }
          clearInterval(this.signTimer);
          this.setState({
            signing: false,
            signTimerCount: 0,
          });
        } else {
          if (!this.state.loading && this.state.lon && this.state.lat && this.state.address) {
            if (this.state.signTimerCount < 3) {
              const delay = 2 - this.state.signTimerCount;
              setTimeout(this.doSign, delay * 1000);
            } else {
              this.doSign();
            }
            clearInterval(this.signTimer);
          } else {
            this.setState({
              signTimerCount: this.state.signTimerCount + 1,
            });
          }
        }
      }, 1000);
      return;
    } else {
      // 已有地理位置信息，显示挖宝动画3s后发送打卡请求
      setTimeout(() => {
        this.doSign();
      }, 3000);
    }
  }
  /**
   * @description 发送请求签到
   */
  doSign = () => {
    const { lon, lat, address } = this.state;
    axios.post('/api/sign', {
      location: {
        type: 'Point',
        coordinates: [
          lon, lat
        ],
        address
      }
    })
      .then((res) => {
        // 根据返回值确定打卡的奖品
        console.log(res.data.data);
        const rewardType = res.data.data.rewardType;
        Toast.hide();
        // 显示打卡挖宝结果
        const result = this.getRewardForType(rewardType);
        this.setState({
          signing: false,
          success: true,
          signSuccessTime: moment().format('HH:mm'),
          successTips: true,
          result,
        });
      })
      .catch(err => {
        if (err.response.status === 401) {
          Toast.fail('登录超时！');
        } else {
          Toast.fail('系统错误！');
        }
      });
  }
  /**
   * @description 根据奖品type获取奖品信息
   * @param {Number} type 奖品type
   */
  getRewardForType = (type) => {
    const jitangLength = jitang.length;
    switch (type) {
      case 0:
        return {
          imgName: 'stone',
          name: '什么破石头呀',
          slogan: jitang[getRandom(0, jitangLength - 1)],
        };
      case 1:
        return {
          imgName: 'iron',
          name: '铁矿也不错啦',
          slogan: jitang[getRandom(0, jitangLength - 1)],
        };
      case 2:
        return {
          imgName: 'gold',
          name: '发财啦，黄金呀',
          slogan: jitang[getRandom(0, jitangLength - 1)],
        };
      case 3:
        return {
          imgName: 'diamond',
          name: 'Diamond, Amazing ！！',
          slogan: jitang[getRandom(0, jitangLength - 1)],
        };
      case -1:
        return {
          imgName: 'bomb',
          name: '有炸弹，快跑',
          slogan: jitang[getRandom(0, jitangLength - 1)],
        };
      default: return {};
    }
  }
  /**
   * @description 关闭成功提示信息
   */
  onOk = () => {
    this.setState({
      successTips: false,
    });
  }
  /**
   * @description 查看个人轨迹图
   */
  viewTimeline = () => {
    zx.toForwardV3({
      url: `${window.location.origin}/timeline`,
      flag: 1,
      needToolbar: true,
      title: '个人轨迹图',
      fail: function (err) {
        window.signThis.props.history.push('/timeline');
      }
    });
  }
  /**
   * @description 查看全员热力分布图
   */
  viewHotmap = () => {
    zx.toForwardV3({
      url: `${window.location.origin}/hotmap`,
      flag: 1,
      needToolbar: true,
      title: '热力分布图',
      fail: function (err) {
        window.signThis.props.history.push('/hotmap');
      }
    });
  }

  render() {
    const { userInfo } = this.props;
    const { btnActive, successActive, successTips, success, signSuccessTime, signTime, result, signing } = this.state;
    return (
      <div className="sign-wrap">
        {
          success ? (
            <div className="sign-btn-box">
              <div className="sign-btn sign-btn-success">
                <div className="txt">已打卡</div>
                <div className="time">{signSuccessTime}</div>
              </div>
              <img alt="" src={require('../../assets/img/miner.jpg')} className="miner" />
            </div>
          ) : (
            <div>
              <div
                className="sign-btn-box"
                onTouchStart={() => this.setState({ btnActive: true })}
                onTouchEnd={() => this.setState({ btnActive: false })}
                onClick={this.signStart}>
                <div className={btnActive ? 'sign-btn sign-btn-active' : 'sign-btn'}>
                  <div className="txt">打卡</div>
                  <div className="time">{signTime}</div>
                </div>
                <img alt="" src={require('../../assets/img/miner.jpg')} className="miner" />
                <div className="btn-water btn-water1"></div>
                <div className="btn-water btn-water2"></div>
                <div className="btn-water btn-water3"></div>
                <div className="btn-water btn-water4"></div>
                <div className="btn-water btn-water5"></div>
                <div className="btn-water btn-water6"></div>
              </div>
            </div>
            
          )
        }

        {
          signing ? (
            <div className="signing-box">
              <div className="mask"></div>
              <div className="signing-body">
                <img src={require('../../assets/img/signing.gif')} alt="" className="img" />
              </div>
            </div>
          ) : null
        }

        {
          successTips ? (
            <div className="success-tips">
              <div className="mask"></div>
              <div className="tips-panel">
                <div className="tips-time">{signSuccessTime}</div>
                <div className="tips-body">
                  <img alt="" src={require(`../../assets/img/mye/${result.imgName}.gif`)} className="tips-gif" />
                  <img alt="" src={require(`../../assets/img/reward/${result.imgName}.png`)} className="tips-img" />
                  <div className="reward-desc">{result.name}</div>
                  <div className="slogan">{result.slogan}</div>
                </div>
                <div className="tips-fotter">
                  <div
                    className={successActive ? "ok-btn ok-btn-active" : "ok-btn"}
                    onTouchStart={() => this.setState({ successActive: true })}
                    onTouchEnd={() => this.setState({ successActive: false })}
                    onClick={this.onOk}
                  >知道了</div>
                </div>
              </div>
            </div>
          ) : null
        }

        {
          userInfo && userInfo.statisFlag ? (
            <div>
              <div className="statis-button" onClick={this.viewTimeline}>
                <img className="prefix-icon" alt="" src={require('../../assets/img/statis.svg')} />
                <div>个人轨迹</div>
              </div>
              <div className="statis-button statis-button2" onClick={this.viewHotmap}>
                <img className="prefix-icon" alt="" src={require('../../assets/img/statis.svg')} />
                <div>热力图</div>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

Sign.propTypes = {
  userInfo: PropTypes.object,
};

export default Sign;
