/* @desc 打卡个人轨迹分布图
 * @Author: guoguo;
 * @Date: 2020-04-06 11:03:25;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2020-04-06 11:03:25;
 */
import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { List, DatePickerView, Toast } from 'antd-mobile';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './TimeLine.less';

const ListItem = List.Item;
const defaultAvatar = require('../../assets/img/default-avatar.png');

@connect(
  state => ({
    selectUser: state.Org.selectUser,
  })
)
class TimeLine extends Component{
  constructor(props) {
    super(props);
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth > 500) {
      clientWidth = 500;
    }
    this.state = {
      timelineData: [], // 用户打卡数据
      noData: false, // 没有统计数据
      loadErr: false, // 获取统计数据失败
      contentH: document.documentElement.clientHeight - (340 / 75) * (clientWidth / 10) -44,
      datePickervisible: false,
      startDate: new Date(moment().subtract(7, 'days').valueOf()), //  默认开始时间为七天前
      endDate: new Date(), // 默认结束时间为当天
      selectStartDate: new Date(moment().subtract(7, 'days').valueOf()),
      selectEndDate: new Date(),
    };
  }

  componentDidMount() {
    // 初始化地图控件
    const map = new window.AMap.Map("hot-container", {
      resizeEnable: true,
      center: [114.499904, 30.555346],
      zoom: 12
    });
    this.setState({
      map,
    });
    // 渲染打卡轨迹marker
    this.renderMarkers(map);
  }
  /**
   * @description 渲染用户打卡规避覆盖物
   * @param {Object} map 地图实例
   */
  renderMarkers = (map) => {
    const { selectUser } = this.props;
    if (!selectUser) {
      return;
    }
    const infoWindow = new window.AMap.InfoWindow({ offset: new window.AMap.Pixel(0, -30) });
    this.getTimelinepData(map, infoWindow);
    this.setState({
      infoWindow,
    });
  }
  /**
   * @description 获取热力图数据，并应用到地图上
   * @param {Object} map 地图实例
   * @param {Object} infoWindow 覆盖物实例
   * @param {Object} startDate 开始时间(YYYY-MM-DD)
   * @param {Object} endDate 结束时间(YYYY-MM-DD)
   */
  getTimelinepData = (map, infoWindow, startDate, endDate) => {
    const { selectUser } = this.props;
    // 先清空所有marker
    map.clearMap();
    // 覆盖物点击事件
    function markerClick(e, marker, data) {
      infoWindow.setContent(e.target.content);
      infoWindow.open(map, e.target.getPosition());
    }
    this.setState({
      noData: false,
      loadErr: false,
    });
    // 根据当前选择的用户获取打卡轨迹数据
    axios.get('/api/timeline', {
      params: { username: selectUser.id, startDate, endDate }
      // params: {
      //   userId: '69ceefa9959e445cbf2b8cc24bbbd6aa',
      //   startDate, endDate
      // }
    })
      .then(res => {
        console.log('getTimelinepData res is: ', res.data);
        const timelineData = res.data.data || [];
        if (!timelineData.length) {
          this.setState({
            noData: true,
          });
        }
        // 设置数据集到地图
        for (let i = 0; i < timelineData.length; i ++) {
          const marker = new window.AMap.Marker({
            position: [timelineData[i].lng, timelineData[i].lat],
            map
          });
          marker.content = `最近打卡时间：${moment(timelineData[i].signDate).format('MM-DD HH:mm')}<br />${timelineData[i].address}`;
          marker.on('click', markerClick );
        }

        map.setFitView();
        this.setState({
          timelineData,
        });
      })
      .catch(err => {
        this.setState({
          loadErr: true,
        });
      });
  }
  /**
   * @description 选择用户来查看统计数据
   */
  selectUser = () => {
    this.props.history.push('/select');
  }
  /**
   * @description 渲染打卡item
   */
  renderDkItems = () => {
    const { timelineData } = this.state;
    return timelineData.map((item, index) => (
      <div key={index} className="sign-item-box">
        <div className="date">{moment(item.signDate).format('MM-DD HH:mm')}</div>
        <div className="address">
          <div className="prefix-icon">
            <img src={require('../../assets/img/loc.svg')} alt="" />
          </div>
          <div className="text">{item.address}</div>
        </div>
      </div>
    ));
  }
  /**
   * @description 打开选择时间区间picker
   */
  onSelectDateOpen = () => {
    const { selectStartDate, selectEndDate } = this.state;
    this.setState({
      startDate: new Date(selectStartDate),
      endDate: new Date(selectEndDate),
      datePickervisible: true,
    });
  }
  /**
   * @description 选择时间范围确定
   */
  onSelectDateOk = () => {
    console.log(1);
    const { selectUser } = this.props;
    const { startDate, endDate, map, infoWindow } = this.state;
    if (startDate.getTime() > endDate.getTime()) {
      Toast.info('开始时间不能大于结束时时间');
      return;
    }
    if (!selectUser) {
      Toast.info('请先选择用户');
      this.setState({
        datePickervisible: false,
      });
      return;
    }
    this.setState({
      selectStartDate: new Date(startDate),
      selectEndDate: new Date(endDate),
      datePickervisible: false,
    }, () => {
      // 根据当前选择的时间范围查询数据
      this.getTimelinepData(map, infoWindow, startDate, endDate);
    });
  }
  /**
   * @description 选择时间范围取消
   */
  onSelectDateCancel = () => {
    this.setState({
      datePickervisible: false,
    });
  }

  render() {
    const { noData, loadErr, startDate, endDate, selectStartDate, selectEndDate } = this.state;
    const { selectUser } = this.props;
    return (
      <div className="timeline-wrap">
        <List>
          <ListItem arrow="horizontal" extra={selectUser ? selectUser.name : '请选择'} onClick={this.selectUser}>
            个人统计
          </ListItem>
        </List>
        <div id="hot-container"></div>
        <div className="picker-select" onClick={this.onSelectDateOpen}>
          <div className="prefix-icon">
            <img src={require('../../assets/img/picker2.svg')} alt="" />
          </div>
          <div className="text">时间范围</div>
        </div>
        {
          loadErr ? (
            <div className="err-tips">获取个人统计数据失败</div>
          ) : (
            noData ? (
              <div className="err-tips">暂无统计数据</div>
            ) : (
              <div className="content-box" style={{ height: this.state.contentH }}>
                {
                  selectUser ? (
                    <div className="user-box">
                      <div className="avatar">
                        {
                          selectUser && selectUser.headUrl ? (
                            <img src={selectUser.headUrl} alt="" onError={e => e.target.src = defaultAvatar} />
                          ) : (
                            <img src={defaultAvatar} alt="" />
                          )
                        }
                      </div>
                      <div className="name">{selectUser.name}</div>
                      <div className="date">{`${moment(selectStartDate).format('YYYY-MM-DD')} ~ ${moment(selectEndDate).format('YYYY-MM-DD')}`}</div>
                    </div>
                  ) : null
                }
                <div className="sign-content">
                  {this.renderDkItems()}
                </div>
              </div>
            )
          )
        }
        {
          this.state.datePickervisible ? (
            <div className="my-picker-view">
              <div className="mask" onClick={this.onSelectDateCancel}></div>
              <div className="content">
                <div className="oper-box">
                  <div className="oper-btn" onClick={this.onSelectDateCancel}>取消</div>
                  <div className="oper-btn" onClick={this.onSelectDateOk}>确定</div>
                </div>
                <div className="title">开始时间：</div>
                <DatePickerView
                  mode="date"
                  value={startDate}
                  onChange={date => this.setState({ startDate: date })}
                />
                <div className="title">结束时间：</div>
                <DatePickerView
                  mode="date"
                  value={endDate}
                  onChange={date => this.setState({ endDate: date })}
                />
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

TimeLine.propTypes = {
  selectUser: PropTypes.object,
};

export default TimeLine;
