import {
  USER_INFO
} from '../types/Base';

import axios from 'axios';
/**
 * @description 获取当前登录用户的用户信息
 */
export function getUserInfo() {
  return dispatch => {
    return axios.get('/api/user/info')
      .then(res => {
        dispatch({
          type: USER_INFO,
          userInfo: res.data.data,
        });
        return Promise.resolve(res.data.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}
/**
 * @description 更具验证码登陆，并返回用户信息
 * @param {String} verCode 验证码
 */
export function LoginForCode(verCode) {
  return (dispatch) => {
    return axios.post('/api/user/code', {
      verCode
    })
      .then(res => {
        dispatch({
          type: USER_INFO,
          userInfo: res.data.data,
        });
        return Promise.resolve(res.data.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}

