import {
  SELECT_USER,
} from '../types/Org';

import axios from 'axios';
import * as localForage from 'localforage';
/**
 * @description 获取机构树根节点列表（组织机构列表）
 * @param {Srting} domain 当前产品域名
 */
export function getOrgRoot(domain) {
  return () => {
    return axios.post('/proxy_org/address/init', { domain })
      .then(res => {
        localForage.setItem('root_list', res.data.data);
        return Promise.resolve(res.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}
/**
 * @description 获取机构树二级列表（组织机构一级部门列表）
 * @param {String} orgId 根节点id（组织机构id）
 * @param {Srting} domain 当前产品域名
 */
export function getDeptList(orgId, domain){
  return () => {
    return axios.post('/proxy_org/address/getRoot', {
      orgId,
      domain,
    })
      .then(res => {
        localForage.setItem(`dept_list_${orgId}`, res.data.data);
        return Promise.resolve(res.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}
/**
 * @description 获取下级机构和成员列表
 * @param {String} deptId 上级节点id（部门id）
 * @param {Srting} domain 当前产品域名
 */
export function getSubAndPerson(deptId, domain) {
  return () => {
    return axios.post('/proxy_org/address/getSubAndPerson', {
      deptId,
      domain,
    })
      .then(res => {
        localForage.setItem(`sub_list_${deptId}`, res.data.data);
        return Promise.resolve(res.data);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };
}
/**
 * @description 保存当前选择的用户
 * @param {Object} user 用户信息
 */
export function selectUser(user) {
  return dispatch => {
    dispatch({
      type: SELECT_USER,
      selectUser: user,
    });
  };
}