/* @desc 选择用户
 * @Author: guoguo;
 * @Date: 2020-04-07 10:15:22;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2020-04-07 10:15:22;
 */

import React, { Component } from 'react';
import { List, Toast } from 'antd-mobile';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as action from '../../actions/Org';
import { bindActionCreators } from 'redux';
import * as localForage from 'localforage';

const ListItem = List.Item;

@connect(
  state => ({
    userInfo: state.Base.userInfo,
  }),
  dispatch => ({
    actions: bindActionCreators(action, dispatch),
  })
)
class Root extends Component{
  constructor(props) {
    super(props);
    this.state = {
      orgList: [],
    };
  }
  componentWillMount() {
    const { userInfo } = this.props;
    Toast.loading('loading..', 0);
    // 尝试从缓存中获取当前域名下的组织机构列表
    localForage.getItem('root_list')
      .then(result => {
        if (result && result.length) {
          // 存在缓存数据
          Toast.hide();
          this.setState({
            orgList: result,
          });
        }
      });
    // 从远端获取结构根节点
    this.props.actions.getOrgRoot(userInfo.domain)
      .then(res => {
        Toast.hide();
        this.setState({
          orgList: res.data || [],
        });
      });
  }

  /**
   * @description 渲染组织机构列表
   */
  renderOrgList = () => {
    const { orgList } = this.state;
    return orgList.map(item => (
      <ListItem arrow="horizontal" multipleLine key={item.id} onClick={() => this.goToDept(item.id)}>
        {item.name}
      </ListItem>
    ));
  }
  /**
   * @description 跳转到机构下方部门列表页
   */
  goToDept = (orgId) => {
    this.props.history.push(`/select/dept/${orgId}`);
  }

  render() {
    return (
      <div>
        <List>
          {
            this.renderOrgList()
          }
        </List>
      </div>
    );
  }
}

Root.propTypes = {
  actions: PropTypes.object,
};

export default Root;
