/* @desc 打卡分布热力图
 * @Author: guoguo;
 * @Date: 2020-04-06 11:03:25;
 * @Last Modified by:   guoguo;
 * @Last Modified time: 2020-04-06 11:03:25;
 */
import React, { Component } from 'react';
import { Toast, DatePickerView, ListView } from 'antd-mobile';
import axios from 'axios';
import moment from 'moment';

require('./HotMap.less');

function MyBody(props) {
  return (
    <div className="content-box">
      <div className="sign-content">
        {props.children}
      </div>
    </div>
  );
}

export default class HotMap extends Component{
  constructor(props) {
    super(props);
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth > 500) {
      clientWidth = 500;
    }
    const getSectionData = (dataBlob, sectionID) => dataBlob[sectionID];
    const getRowData = (dataBlob, sectionID, rowID) => dataBlob[rowID];
    this.state = {
      hotmapData: [],
      signList: [],
      contentH: document.documentElement.clientHeight - (420 / 75) * (clientWidth / 10),
      datePickervisible: false,
      startDate: new Date(moment().subtract(7, 'days').valueOf()), //  默认开始时间为七天前
      endDate: new Date(), // 默认结束时间为当天
      selectStartDate: new Date(moment().subtract(7, 'days').valueOf()),
      selectEndDate: new Date(),

      // 分页相关
      NUM_SECTIONS: 1,
      dataSource: new ListView.DataSource({
        getRowData,
        getSectionHeaderData: getSectionData,
        rowHasChanged: (row1, row2) => row1 !== row2,
        sectionHeaderHasChanged: (s1, s2) => s1 !== s2,
      }),
      dataBlobs: {},
      sectionIDs: [],
      rowIDs: [],
      pageIndex: 0,  // 页码
      viewLoading: true,   // 数据加载中的loading
      loadErr: false,   // 数据加载错误
      noData: false,  // 没有数据
      hasMore: false,  // 是否存在下一页数据
    };
  }

  componentDidMount() {
    // 获取所有打卡记录
    this.getSignList(0, 10);
    window.onload = () => {
      const map = new window.AMap.Map("hot-container", {
        resizeEnable: true,
        center: [114.499904, 30.555346],
        zoom: 12
      });

      if (!this.isSupportCanvas) {
        Toast.info('当前手机暂不支持查看热力图');
      }

      let heatmap;
      map.plugin(["AMap.Heatmap"], () => {
        // 初始化heatmap对象
        heatmap = new window.AMap.Heatmap(map, {
          radius: 25, // 给定半径
          opacity: [0, 0.8]
        });
        this.setState({
          heatmap
        });
        this.getHotmapData(heatmap);
      });
    };
  }
  /**
   * @description 获取打卡记录列表
   */
  getSignList = (page, size, startDate, endDate) => {
    axios.get('/api/sign', {
      params: { page, size, startDate, endDate }
    })
      .then(res => {
        const data = res.data.data || [];
        this.setState({
          loadErr: false,
          viewLoading: false,
          hasMore: data.length === 10,
          noData: !data.length && page <= 0,
          signList: page && page > 0 ? [...this.state.signList, ...data] : [...data],
        }, () => {
          this.genData(page, data.length);
        });
      })
      .catch(err => {
        this.setState({
          viewLoading: false,
          loadErr: true,
        });
      });
  }
  /**
   * @desc 获取数据结束
   * @param {String} pIndex 页码
   * @param {String} numRowPerSection 当前页码数据数量
   */
  genData = (pIndex = 0, numRowPerSection = 0) => {
    const { NUM_SECTIONS } = this.state;
    const dataBlobs = Object.assign({}, this.state.dataBlobs);
    let sectionIDs = this.state.sectionIDs.concat();
    let rowIDs = this.state.rowIDs.concat();
    for (let i = 0; i < NUM_SECTIONS; i++) {
      const ii = (pIndex * NUM_SECTIONS) + i;
      let sectionName = '';
      if (!pIndex && sectionIDs.length > 0) {
        sectionName = sectionIDs[0].match(/[A-Z]/g) ? sectionIDs[0].toLowerCase() : sectionIDs[0].toUpperCase();
        sectionIDs = [sectionName];
      } else {
        sectionName = `Section ${ii}`;
        sectionIDs.push(sectionName);
      }
      dataBlobs[sectionName] = sectionName;
      rowIDs[ii] = [];
      for (let jj = 0; jj < numRowPerSection; jj++) {
        const rowName = `S${ii}, R${jj}`;
        rowIDs[ii].push(rowName);
        dataBlobs[rowName] = rowName;
      }
    }
    sectionIDs = [...sectionIDs];
    rowIDs = [...rowIDs];
    this.setState({
      sectionIDs,
      rowIDs,
      dataBlobs,
      dataSource: this.state.dataSource.cloneWithRowsAndSections(dataBlobs, sectionIDs, rowIDs),
    });
  }
  /**
   * @description 判断当前浏览器是否支持canvas
   */
  isSupportCanvas = () => {
    const elem = document.createElement('canvas');
    return !!(elem.getContext && elem.getContext('2d'));
  }
  // 获取热力图数据，并应用到地图上
  getHotmapData = (heatmap, startDate, endDate) => {
    axios.get('/api/hotmap', {
      params: { startDate, endDate }
    })
      .then(res => {
        const hotmapData = res.data.data || [];
        // 设置数据集到地图
        if (heatmap) {
          heatmap.setDataSet({
            data: hotmapData,
            max: 100
          });
        }
      });
  }
  /**
   * @description 打开选择时间区间picker
   */
  onSelectDateOpen = () => {
    const { selectStartDate, selectEndDate } = this.state;
    this.setState({
      startDate: new Date(selectStartDate),
      endDate: new Date(selectEndDate),
      datePickervisible: true,
    });
  }
  /**
   * @description 选择时间范围确定
   */
  onSelectDateOk = () => {
    console.log(1);
    const { startDate, endDate } = this.state;
    if (startDate.getTime() > endDate.getTime()) {
      Toast.info('开始时间不能大于结束时时间');
      return;
    }
    this.setState({
      selectStartDate: new Date(startDate),
      selectEndDate: new Date(endDate),
      datePickervisible: false,
    }, () => {
      this.refresh();
    });
  }
  /**
   * @description 选择时间范围取消
   */
  onSelectDateCancel = () => {
    this.setState({
      datePickervisible: false,
    });
  }
  /**
   * @description 上拉加载
   */
  onEndReached = () => {
    const pageIndex = this.state.pageIndex + 1;
    if (this.state.viewLoading || !this.state.hasMore) return;
    this.setState({
      viewLoading: true,
      pageIndex
    }, () => {
      // 重新获取数据
      const { startDate, endDate, heatmap } = this.state;
      this.getHotmapData(heatmap, startDate, endDate);
      this.getSignList(pageIndex, 10, startDate, endDate);
    });
  }
  /**
   * @description 刷新
   */
  refresh = () => {
    let mySectionIDs = [];
    let myRowIDs = [];
    const myDataBlobs = {};
    const dataBlobs = Object.assign({}, this.state.dataBlobs);
    const sectionIDs = [...this.state.sectionIDs];
    const rowIDs = [...this.state.rowIDs];
    for (const item in dataBlobs) {
      if (item.match(/[A-Z]/g)) {
        myDataBlobs[item.toLowerCase()] = dataBlobs[item].toLowerCase();
      } else {
        myDataBlobs[item.toUpperCase()] = dataBlobs[item].toUpperCase();
      }
    }
    mySectionIDs = sectionIDs.map(item => {
      if (item.match(/[A-Z]/g)) {
        return item.toLowerCase();
      }
      return item.toUpperCase();
    });
    myRowIDs = rowIDs.map(item => {
      return item.map(item2 => {
        if (item2.match(/[A-Z]/g)) {
          return item2.toLowerCase();
        }
        return item2.toUpperCase();
      });
    });
    this.setState({
      pageIndex: 0,
      dataBlobs: {},
      sectionIDs: [],
      rowIDs: [],
      signList: [],
      dataSource: this.state.dataSource.cloneWithRowsAndSections(myDataBlobs, mySectionIDs, myRowIDs),
    }, () => {
      const { startDate, endDate, heatmap } = this.state;
      // 重新获取数据
      this.getHotmapData(heatmap, startDate, endDate);
      this.getSignList(0, 10, startDate, endDate);
    });
  }

  render() {
    const { startDate, endDate, loadErr, noData, signList, viewLoading, contentH } = this.state;
    const row = (rowData, sectionID, rowID) => {
      const [S, R] = rowData.split(',');
      const index = parseInt(`${S.split('S')[1]}${R.split('R')[1]}`, 10);
      const obj = signList[index];
      if (obj) {
        const parseLoc = JSON.parse(obj.location);
        return (
          <div key={index} className="sign-item-box">
            <div className="sign-item-head">
              <div className="user">{obj.cnname}</div>
              <div className="date">{moment(obj.signDate).format('MM-DD HH:mm')}</div>
            </div>
            <div className="address">
              <div className="prefix-icon">
                <img src={require('../../assets/img/loc.svg')} alt="" />
              </div>
              <div className="text">{parseLoc.address}</div>
            </div>
          </div>
        );
      }
      return null;
    };
    return (
      <div className="hotmap-wrap">
        <div id="hot-container"></div>
        <div className="picker-select" onClick={this.onSelectDateOpen}>
          <div className="prefix-icon">
            <img src={require('../../assets/img/picker2.svg')} alt="" />
          </div>
          <div className="text">时间范围</div>
        </div>

        <ListView
          dataSource={this.state.dataSource}
          renderFooter={() => (<div className="err-tips">
            {
              viewLoading ? '加载中...' : (
                loadErr ? '获取数据失败' : (
                  noData ? '暂无统计数据' : '没有更多数据'
                )
              )
            }
          </div>)}
          renderBodyComponent={() => <MyBody />}
          renderRow={row}
          style={{ height: contentH }}
          pageSize={4}
          onScroll={() => { console.log('scroll'); }}
          scrollRenderAheadDistance={500}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10}
        />

        {
          this.state.datePickervisible ? (
            <div className="my-picker-view">
              <div className="mask" onClick={this.onSelectDateCancel}></div>
              <div className="content">
                <div className="oper-box">
                  <div className="oper-btn" onClick={this.onSelectDateCancel}>取消</div>
                  <div className="oper-btn" onClick={this.onSelectDateOk}>确定</div>
                </div>
                <div className="title">开始时间：</div>
                <DatePickerView
                  mode="date"
                  value={startDate}
                  onChange={date => this.setState({ startDate: date })}
                />
                <div className="title">结束时间：</div>
                <DatePickerView
                  mode="date"
                  value={endDate}
                  onChange={date => this.setState({ endDate: date })}
                />
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}
