import {
  SELECT_USER,
} from '../types/Org';

const initialState = {
  selectUser: null, // 当前选中的用户
};

export default function base(state = initialState, action) {
  switch (action.type) {
    case SELECT_USER:
      return {
        ...state,
        selectUser: action.selectUser,
      };
    default:
      return state;
  }
}
